<template>
  <div id="patient-treatments">
    <div class="main-container">
      <v-glauco-icon class="icon" />
      <p class="main-title">Doenças registradas</p>
    </div>

    <hr />

    <div
      class="user-treatment"
      v-for="userTreatment in userTreatments"
      :key="userTreatment.id"
    >
      <b-container class="mb-2">
        <b-row>
          <b-col cols="3">
            <div class="d-flex flex-column">
              <p>Nome da doença</p>
              <span class="data disease">{{ userTreatment.disease }}</span>
            </div>
          </b-col>

          <b-col cols="3">
            <div class="d-flex flex-column">
              <p>Data registrada</p>
              <span class="data date">
                {{ moment(userTreatment.created_at).format('DD/MM/YYYY') }}
              </span>
            </div>
          </b-col>

          <b-col cols="3">
            <div class="d-flex flex-column">
              <p>Medicamentos cadastrados</p>
              <span class="data default">
                {{ userTreatment.__meta__.medications_count }}
                {{
                  userTreatment.__meta__.medications_count > 1
                    ? 'medicamentos'
                    : 'medicamento'
                }}
              </span>
            </div>
          </b-col>

          <b-col cols="3">
            <div class="d-flex flex-column">
              <p>Sucesso no tratamento</p>
              <span class="data text-success">
                {{ userTreatment.success }}
              </span>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <hr />
    </div>
  </div>
</template>

<script>
import GlaucoIcon from '@/components/Glauco/GlaucoIcon'

export default {
  name: 'PatientTreatments',
  data() {
    return {
      id: this.$route.params.patientId,
      userTreatments: [],
      userTreatmentsToFilter: []
    }
  },
  components: {
    'v-glauco-icon': GlaucoIcon
  },
  async created() {
    await this.getGlaucoUserTreatments()
  },
  methods: {
    async getGlaucoUserTreatments() {
      try {
        const response = await this.api.glaucoUserTreatments(this.id)

        this.userTreatments = response.data.map(userTreatment => {
          const completeTreatment = userTreatment.medications.reduce(
            (acc, medication) => {
              return acc + parseInt(medication.__meta__.alerts_dripped)
            },
            0
          )

          let totalTreatment = userTreatment.medications.reduce(
            (acc, medication) => {
              return acc + parseInt(medication.__meta__.total_alerts)
            },
            0
          )
          totalTreatment = totalTreatment === 0 ? 1 : totalTreatment
          return {
            ...userTreatment,
            success:
              ((completeTreatment / totalTreatment) * 100).toFixed(2) + '%'
          }
        })

        this.updateUserTreatmentsToFilter()
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    updateUserTreatmentsToFilter() {
      this.userTreatmentsToFilter = this.userTreatments.map(userTreatment => {
        return {
          value: userTreatment.id,
          label: userTreatment.disease
        }
      })
      this.userTreatmentsToFilter.unshift({
        value: null,
        label: 'Todos os tratamentos'
      })
      this.$emit('change-user-treatments', this.userTreatmentsToFilter)
    }
  }
}
</script>

<style lang="scss">
#patient-treatments {
  background-color: var(--neutral-000);
  border-radius: 8px;
  padding: 10px 15px;
  .main-container {
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .icon {
      width: 17px;
      height: 17px;
      margin-right: 1%;
    }

    .main-title {
      color: var(--dark-blue);
      font-size: min(1.2vw, 16px);
      font-family: 'Red Hat Display';
      font-weight: 500;
    }
  }

  .user-treatment {
    p {
      font-family: 'Nunito Sans';
      font-size: min(1vw, 14px);
      font-weight: 600;
      color: var(--dark-blue);
    }

    .data {
      font-family: 'Red Hat Display';
      font-size: min(2.6vw, 18px);
      font-weight: 700;

      &.disease {
        color: var(--neutral-600);
      }

      &.date {
        color: var(--blue-500);
      }

      &.default {
        color: var(--type-placeholder);
      }
    }
  }

  .user-treatment:last-child hr {
    display: none;
  }
}
</style>

